import $ from 'jquery';
import {useEffect,useState} from 'react';
import { appGetUrlPath } from '../function_lib/common_cms';
import TextToHtml from './text_html';

import './comp_blog_land.css';
export const Comp_Blog_Land=(props)=>{
    let appPathCMS=appGetUrlPath();

    const [stImgPath,setImgPath]=useState("");
    // alert("i m in landing");
    useEffect(()=>{
        if(props.blogData["col4"]!==""){
            setImgPath(props.blogData["col4"]);
        }
        // alert(props.blogData["col2"]);
    
    },[]);


    // if(props.blogData["col4"]!==""){
    //     setImgPath(props.blogData["col4"]);
    // }
    
    
    return(<div className="blog-posts pt-70px pr-10">
       
        <div id="leading_blog" className="single-blog-post  blog-grid-post">
            <div className="blog-post-media">
                
                {props.blogData["col6"].trim()!==""?<div className="blog-image single-blog">
                <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen width="100%"
    src={props.blogData["col6"]}>
    </iframe>
                </div>:<div className="blog-image single-blog">
                    {stImgPath!=="" && props.requireImg===true?<img src={appPathCMS+stImgPath} alt="blog" />:''}
                    
                </div>
                }
                 
            </div>
            {props.requireImg===true?
            <div className="pro-details-social-info">
                            <span>Share</span>
                            <div className="social-info">
                                <ul className="d-flex">
                                    <li>
                                        <a href="#"><i className="ion-social-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-google"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>:''}

            <div className="blog-post-content-inner mt-6">
                <div className="blog-athor-date">
                    {/* <a className="blog-date" href="#">14 November</a> */}
                </div>
                <h4 className="blog-title">
                    {/* <a className="display-overflow" href="javascript:void(0)">{this.props.blogData["col1"]}</a> */}
                    
                        <h4 className="black">{props.blogData["col1"]}</h4>
                    
                    </h4>
                <p id="blog_landing">
                <TextToHtml text={props.blogData["col2"]}/>
                <TextToHtml text={props.blogData["col3"]}/>
                </p>
            
            </div>
            
        </div>
        
    </div>);
}
export default Comp_Blog_Land;