import $ from 'jquery';
import {useEffect,useState} from 'react';
import { appGetUrlPath } from '../function_lib/common_cms';
import TextToHtml from './text_html';
import './comp_recent_blog.css';

export const Comp_Recent_Blog=(props)=>{
    let appPathCMS=appGetUrlPath();
    let isRecent=true;
return(<div className="sticky left-sidebar pt-70px shop-sidebar-wrap">
    
    <div className="sidebar-widget recent-blog-wid">
        <h3 className="sidebar-title">Recent Post</h3>
        {/* <div className="search-widget">
            <form action="#">
                <input placeholder="Search entire store here ..." type="text" />
                <button type="submit"><i className="icon-magnifier"></i></button>
            </form>
        </div> */}
        <br></br>
        <div className="recent-post-widget">
            {(isRecent)? props.blogData.map((item,index)=>{
                 var urlBlog="/blog/"+item.col1+"?rf="+item.e_id;
                 if(item["col7"]!==""){
                  urlBlog="../"+item["col7"];    
                 }
               //var urlBlog="/blog/"+item.col1+"?rf="+item.e_id;
               return <div className="recent-single-post d-flex">
               <div className="thumb-side">
                   <a href={urlBlog}><img src={appPathCMS+item.col4} alt="" /></a>
               </div>
               <div className="media-side">
                   <a href={urlBlog}><h6>{item.col1}</h6></a>
                   
                  
               </div>
           </div>
            }):''}
       
        </div>
    </div>
  </div>)
}
export default Comp_Recent_Blog;