import './comp_website_counter.css'

const Comp_Website_Counter=(props)=>{
    return(

        
            <div class="card mt-2 bg-none">
                <div class="card-body">
                    <div class="p-0 d-flex  justify-content-between">
                        <h5 class="white">Visitors Statistics</h5>
                        <div class="dot mt-2"></div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 class="text-success mb-2">Today</h6>
                            <p class="mb-0">248</p>
                        </div>
                        <div>
                            <h6 class="text-primary mb-2">Weekly</h6>
                            <p class="mb-0">892</p>
                        </div>
                        <div>
                            <h6 class="text-info mb-2">Monthly</h6>
                            <p class="mb-0">3,427</p>
                        </div>
                        <div>
                            <h6 class="text-danger mb-2">Total</h6>
                            <p class="mb-0">11,048</p>
                        </div>
                    </div>
                </div>
            </div>
        
    );
}

export default Comp_Website_Counter