import $ from 'jquery';
import React,{useEffect,useState} from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useSearchParams,Link,useParams } from 'react-router-dom';

import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,sortListNew} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod_CBack,pgIndexLimit,writeJSLib} from '../function_lib/common_cms';

import Front_Header from './front_header';
import Footer from './footer';


import Article_Listing_n from '../sample_comp_v2/article_listing';
import {Home_Banner_n,Home_Banner2Div_n,Home_Banner_WithNews_n} from '../sample_comp_v2/home_banner';
import Article_Blogs_n from '../sample_comp_v2/article_blogs';
import Left_Img_Txt_Desc_n from '../sample_comp_v2/left_img_txt_desc';
import Single_Img_Msg_n from '../sample_comp_v2/single_img_msg';
import Txt_Description_n from '../sample_comp_v2/txt_description';
import ImageGallery_n from '../sample_comp_v2/image_gallery';
import Video_Slider_n from '../sample_comp_v2/video_slider';
import {Testimonials_n} from '../sample_comp_v2/testimonials';
import Image_Collarge_Grid_n from '../sample_comp_v2/image_collarge_grid';
import Text_Thumbnail_n from '../sample_comp_v2/text_thumbnail';
import Comp_Group_Page from '../sample_comp_v2/comp_group_page';
import {Collapse_Pan_n,Collapse_Pan_Images_n} from '../sample_comp_v2/collapse_pan';
import Card_List from '../sample_comp_v2/card_list';
import Comp_Ashtech_Main_Banner from '../sample_comp_v2/comp_ashtech_main_banner';

import Blog_Listing_New from '../sample_comp_v2/blog_listing_new';
import Top_Banner from '../sample_comp_v2/top_banner';

import Product_Slider from '../sample_comp_v2/product_slider';



export const Index=()=>{
    const location = useLocation();
   
  
     const [loc,setLoc]=useState('');
    const [stPgData,setStPgData]=useState([]);
    const [_pgIndexed,setPgIndexed]=useState([]);

    const [useJSList,setJsList]=useState(0);


    // CMS data calling
    const getCMSData=(_loc)=>{
        
        //alert(_loc);
        var pgMap=[];
        pgMap.push({name:"curl_type",value:"getPgData"});
        pgMap.push({name:"res_func",value:"callBackpgDataNew"});
        pgMap.push({name:"loc",value:_loc});
        pgMap.push({name:"only_cms",value:"d"});
        
       
        pgMap["res_func"]="callBackpgDataNew";
        pgMap["curl_type"]="getPgData";
        CallCMSMapMethod_CBack(this,pgMap,function(data,msg){
            //alert(data);
            let listObj=JSON.parse(data);
            setStPgData(listObj.access);
          

            if(useJSList===0)
                {
                writeJSLib();
                setJsList(1);
            }

            AjaxCallMethod(this,"okSuccCNOT");
        });
        
    }
   

    useEffect(()=>{
        setPgIndexed(pgIndexLimit());
        //alert(location["pathname"].substr(1));
        //setLocation(useLocation());
        //setLoc();
        let _loc=location["pathname"].substr(1);
        if(_loc===""){
            _loc="home";
        }
        setLoc(_loc);
       
        //getCMSData(loc);
        
    },[])
    useEffect(() => {
        if (loc) {
            getCMSData(loc);
        }
    }, [loc]); // Dependency array ensures this runs only when `loc` changes

    // useEffect(()=>{
    //    // alert(loc);
    //    let _loc=location["pathname"].substr(1);
    //    if(_loc===""){
    //        _loc="home";
    //    }
    //    setLoc(_loc);
        
    // },[useJSList])

return(<>
 
    {/* <!-- Navbar & Carousel End --> */}

<Front_Header loc={loc}/>
    {/* add cms componet */}
    {_pgIndexed.map((item_m,mi)=>{
       // alert(mi+"=== "+_pgIndexed.length);
        if(mi===_pgIndexed.length-1){
         //  alert("i m in set state");
          // setJsList(1);
       }
                if(mi===6){
                    if(typeof stPgData[mi]!=="undefined"){
                        //alert(JSON.stringify(this.state.stPgData[mi]));
                    }
                    
                }
return(<>{typeof stPgData[mi]!=="undefined"?<>
    {/*not in use <Home_Banner_n/> */}
    
    {typeof stPgData[mi]["home_banner"]!=="undefined"?<Home_Banner_WithNews_n lsData={stPgData[mi]["home_banner"]} />:''}
    {typeof stPgData[mi]["home_banner2div"]!=="undefined"?<Home_Banner2Div_n lsData={stPgData[mi]["home_banner2div"]} />:''}

    {typeof stPgData[mi]["top_banner"]!=="undefined"?<Top_Banner lsData={stPgData[mi]["top_banner"]} />:''}
    {typeof stPgData[mi]["list_slider"]!=="undefined"?<Article_Blogs_n lsData={stPgData[mi]["list_slider"]} />:''}

    {typeof stPgData[mi]["card_list"]!=="undefined"?<Card_List lsData={stPgData[mi]["card_list"]} />:''}
    
    {typeof stPgData[mi]["img_text_desc_img_left"]!=="undefined"?<Left_Img_Txt_Desc_n lsData={stPgData[mi]["img_text_desc_img_left"]} />:''}
    {typeof stPgData[mi]["img_text_desc_img_right"]!=="undefined"?<Left_Img_Txt_Desc_n lsData={stPgData[mi]["img_text_desc_img_right"]} img_right="true" />:''}
    
    {typeof stPgData[mi]["image_collarge_grid"]!=="undefined"?<Image_Collarge_Grid_n lsData={stPgData[mi]["image_collarge_grid"]} />:''}
    {typeof stPgData[mi]["video_slider"]!=="undefined"?<Video_Slider_n lsData={stPgData[mi]["video_slider"]} />:''}

    {typeof stPgData[mi]["text_thumbnail"]!=="undefined"?<Text_Thumbnail_n lsData={stPgData[mi]["text_thumbnail"]} />:''}

    {typeof stPgData[mi]["text_description"]!=="undefined"?<Txt_Description_n lsData={stPgData[mi]["text_description"]} />:''}

    {typeof stPgData[mi]["article_listing"]!=="undefined"?<Article_Listing_n isSort={loc==="e_news"?true:false} sortparam={["entry_date",false,"date"]} lsData={stPgData[mi]["article_listing"]} />:''}

    {typeof stPgData[mi]["blog_listing"]!=="undefined"?<Blog_Listing_New isSort={loc==="e_news"?true:false} sortparam={["entry_date",false,"date"]} lsData={stPgData[mi]["blog_listing"]} />:''}

    {typeof stPgData[mi]["single_img_text"]!=="undefined"?<Single_Img_Msg_n lsData={stPgData[mi]["single_img_text"]} />:''}

    {typeof stPgData[mi]["image_gallery"]!=="undefined"?<ImageGallery_n lsData={stPgData[mi]["image_gallery"]} />:''}

    {typeof stPgData[mi]["collapse_pan"]!=="undefined"?<Collapse_Pan_Images_n lsData={stPgData[mi]["collapse_pan"]}/>:''}
    
    {typeof stPgData[mi]["testimonials"]!=="undefined"?<Testimonials_n lsData={stPgData[mi]["testimonials"]}/>:''}
    
    
    </>:''}</>);
   
        })
        }
        {/* end of cms part */}
        <Footer/>
</>)
}
export default Index;