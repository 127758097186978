import Comp_Popup_Contact from '../component_version2/comp_popup_contact';
import Comp_Website_Counter from '../sample_comp_v2/comp_website_counter';
import './footer.css';
export const Footer=()=>{

    return(
        <div>
            <Comp_Popup_Contact/>
        <div className="footer-area">
        <div className="footer-container">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">DSB Group</h4>
                                {/* <h4 className="footer-herading">Fast RC & Hobbies LLC</h4> */}
                                <p className="about-text"><b>Business Address:</b><br/>6th Floor, Business Center, The Meydan Hotel GrandStand,<br/>
                                Meydan Road, Nad AI Sheba,   <br/>
                                Dubai, United Arab Emirates(UAE)<br/>
                                <p></p>
                                <b>Phone:</b> +91 96990 99333<br/>
                                <b>Email:</b> info@dsbgroup.in
                                </p>
                                <ul className="link-follow">
                               
                                    <li className="li">
                                        <a className="icon-social-facebook" title="Facebook" href="https://www.facebook.com/sharer/sharer.php?u=ashtechnologies.in"></a>
                                    </li>
                                    <li className="li">
                                        <a className="twitter icon-social-twitter" title="Twitter" href="https://twitter.com/intent/tweet?text=ashtechnologies.in"></a>
                                    </li>
                                    <li className="li">
                                        <a className="tumblr icon-social-tumblr" title="Tumblr" href="http://www.tumblr.com/share/link?url=https://ashtechnologies.in"></a>
                                    </li>
                                    
                                    <li className="li">
                                        <a className="icon-social-instagram" title="Instagram" href="https://www.instagram.com/?url=https://ashtechnologies.in"></a>
                                    </li>
                                    {/* <li className="li">
                                        <a className="linkedin icon-social-linkedin" title="Linkedin" href="#"></a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                       
                        <div className="col-md-6 col-sm-6 pl-10 col-lg-3 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">information</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../company_profile">About Company</a></li>
                                            <li className="li"><a className="single-link" href="../business_profile">Business Profile</a></li>
                                            <li className="li"><a className="single-link" href="../our_founders">Our Founders</a></li>
                                            <li className="li"><a className="single-link" href="../blogs">Blogs</a></li>
                                            <li className="li"><a className="single-link" href="../contactus">Contact Us</a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-2 col-sm-6 mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">Quick Links</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../agricultural_products">Agriculture Products
                    </a>
                                            </li>
                                            <li className="li"><a className="single-link" href="../metal_scrap">Metal Scrap</a></li>
                                            <li className="li"><a className="single-link" href="../sourcing_and_procurement">Sourcing & Procurement</a></li>
                                            {/* <li className="li"><a className="single-link" href="../specialties">Our Specialities</a></li> */}
                                            <li className="li"><a className="single-link" href="../logistics_support">Logistics Support</a></li>
                                            <li className="li"><a className="single-link" href="../custom_trading_solutions">Custom Trading Solutions</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                       
                        <div className="col-md-12 col-lg-3">
                            <div className="single-wedge">
                                <h4 className="footer-herading">newsletter</h4>
                                <div className="footer-links">
                                    
                                    <div id="mc_embed_signup" className="subscribe-form">
                                        <form id="mc-embedded-subscribe-form" className="validate" novalidate="" target="_blank" name="mc-embedded-subscribe-form">
                                            <div id="mc_embed_signup_scroll" className="mc-form">
                                                <input className="email" type="email" required="" placeholder="Your Mail*" name="EMAIL" value="" />
                                                <div className="mc-news" aria-hidden="true">
                                                    <input type="text" value="" tabindex="-1" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef" />
                                                </div>
                                                <div className="clear">
                                                    <button id="mc-embedded-subscribe" className="button btn-primary" type="submit" name="subscribe" value=""><i
                                                            className="icon-cursor"></i> Send Mail</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <Comp_Website_Counter/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        {/* <div className="col-md-6 text-right">
                            <div className="payment-link">
                                <img src="assets/images/icons/payment.png" alt="" />
                            </div>
                        </div> */}
                        <div className="col-md-12 text-left">
                            <p className="copy-text">© Copyright 2025 <strong>DSB Group</strong> <a className="company-name" href="#">
                                {/* <strong>ashtechnologies.in</strong> */}
                                </a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        {/* <Cart_Canvas/> */}
        <div className="offcanvas-overlay"></div>
        </div>)
}
export default Footer;