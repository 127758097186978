import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import TextToHtml from './text_html';
import './top_banner.css';
export function Top_Banner(props){
    var appPath=appGetUrlPath();
    var strResp="";
  //  alert(lsData.length);

  return(<>
  {props.lsData.map((item,index)=>{
    
 var stylBgImg="";
 if(item["col4"]!==""){
    let bgString=`${appPath}${item["col4"].trim()}`;

     stylBgImg={backgroundImage:`url(${bgString})`, "background-size":"cover","background-position":"center","height":"300px"};
 }    

    return(<div style={stylBgImg}  class="breadcrumb-area text-center top-banner">
           <div class="container">
            <div class="row">
                <div class="col-12 align-items-center">
                    <div class="row breadcrumb_box  align-items-center">
                        <div class="col-lg-6 top-title-div  col-md-12 col-sm-12">
                           <center><h1>{item["col1"]}</h1></center>
                           <center><TextToHtml text={item["col2"]}/></center>
                          
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
  })}
  </>)
   
}
export default Top_Banner;