import $ from 'jquery';
import {useEffect,useRef} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';

import Index from './pages/index';
import Blogs_Landing_With_Child from './pages/blogs_landing_with_child';
import Contact_Us from './pages/contact_us';

//import  Test_Index from './pages/test_index';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;
function App() {
  const hasRun = useRef(false);
useEffect(()=>{
  if (hasRun.current) return; // Prevent running twice
  hasRun.current = true;
},[])


  return (
    <Router><Routes >
    {/* <Route exact path="/contactus" component={ContactUs}/> */}
    {/* <Route path="test" element={<Test_Index/>}/> */}

    
    <Route path="/services" element={<Blogs_Landing_With_Child/>} />
    <Route path="/services/:name" element={<Blogs_Landing_With_Child/>} />

    <Route path="/products" element={<Blogs_Landing_With_Child/>} />
    <Route path="/products/:name" element={<Blogs_Landing_With_Child/>} />
    
    <Route path="/articles" element={<Blogs_Landing_With_Child/>} />
    <Route path="/articles/:name" element={<Blogs_Landing_With_Child/>} />

    <Route path="/blogs/:name" element={<Blogs_Landing_With_Child/>}/>
    <Route path="/blog/:name" element={<Blogs_Landing_With_Child/>}/>

    <Route path="/contactus" element={<Contact_Us/>}/>
    <Route path="/contact_us" element={<Contact_Us/>}/>
    
    <Route  path="/*" element={<Index/>}/>

  
</Routes ></Router>
  );
}

export default App;
