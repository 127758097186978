import $ from 'jquery';
import React,{useEffect,useState} from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useSearchParams,Link,useParams } from 'react-router-dom';

import {btnSetColor_CBack,CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,sortListNew} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod_CBack,pgIndexLimit,writeJSLib} from '../function_lib/common_cms';

import Front_Header from './front_header';
import Footer from './footer';


import Article_Listing_n from '../sample_comp_v2/article_listing';
import {Home_Banner_n,Home_Banner2Div_n,Home_Banner_WithNews_n} from '../sample_comp_v2/home_banner';
import Article_Blogs_n from '../sample_comp_v2/article_blogs';
import Left_Img_Txt_Desc_n from '../sample_comp_v2/left_img_txt_desc';
import Single_Img_Msg_n from '../sample_comp_v2/single_img_msg';
import Txt_Description_n from '../sample_comp_v2/txt_description';
import ImageGallery_n from '../sample_comp_v2/image_gallery';
import Video_Slider_n from '../sample_comp_v2/video_slider';
import {Testimonials_n} from '../sample_comp_v2/testimonials';
import Image_Collarge_Grid_n from '../sample_comp_v2/image_collarge_grid';
import Text_Thumbnail_n from '../sample_comp_v2/text_thumbnail';
import Comp_Group_Page from '../sample_comp_v2/comp_group_page';
import {Collapse_Pan_n,Collapse_Pan_Images_n} from '../sample_comp_v2/collapse_pan';
import Card_List from '../sample_comp_v2/card_list';
import Comp_Ashtech_Main_Banner from '../sample_comp_v2/comp_ashtech_main_banner';

import Blog_Listing_New from '../sample_comp_v2/blog_listing_new';

import Product_Slider from '../sample_comp_v2/product_slider';

import './contact_us.css';

export const Contact_Us=()=>{
    const location = useLocation();
   
    const [metaHeaders,setMetaHeaders]=useState({
        top_title:"Dashora and Company",
        top_description:"Dashora and Company",
        top_keywords:"Dashora and Company"
    })
     const [loc,setLoc]=useState('');
    const [stPgData,setStPgData]=useState([]);
    const [_pgIndexed,setPgIndexed]=useState([]);

    const [useJSList,setJsList]=useState(0);

    //contact request --
   const addContactReq=()=>{
        btnSetColor_CBack(this,"contact-form","addContactRequest",function(data,msg){
            if(data!=="0"){
                alert(msg);
                window.location.reload();
            }
        })
    }

    // CMS data calling
    const getCMSData=(_loc)=>{
        
        //alert(_loc);
        var pgMap=[];
        pgMap.push({name:"curl_type",value:"getPgData"});
        pgMap.push({name:"res_func",value:"callBackpgDataNew"});
        pgMap.push({name:"loc",value:_loc});
        pgMap.push({name:"only_cms",value:"d"});
        
       
        pgMap["res_func"]="callBackpgDataNew";
        pgMap["curl_type"]="getPgData";
        CallCMSMapMethod_CBack(this,pgMap,function(data,msg){
            //alert(data);
            let listObj=JSON.parse(data);
            setStPgData(listObj.access);
            if(listObj.length>0){
                setMetaHeaders({top_title:listObj})
            }

            if(useJSList===0)
                {
                writeJSLib();
                setJsList(1);
            }

            AjaxCallMethod(this,"okSuccCNOT");
        });
        
    }
   

    useEffect(()=>{
        setPgIndexed(pgIndexLimit());
        //alert(location["pathname"].substr(1));
        //setLocation(useLocation());
        //setLoc();
        let _loc=location["pathname"].substr(1);
        if(_loc===""){
            _loc="home";
        }
        setLoc(_loc);
        //alert(loc);
        getCMSData(_loc);
        
    },[])
    useEffect(()=>{
       
        
    },[useJSList])

return(<>
 
    {/* <!-- Navbar & Carousel End --> */}

<Front_Header loc={loc}/>
    {/* add cms componet */}
    {_pgIndexed.map((item_m,mi)=>{
       // alert(mi+"=== "+_pgIndexed.length);
        if(mi===_pgIndexed.length-1){
         //  alert("i m in set state");
          // setJsList(1);
       }
                if(mi===6){
                    if(typeof stPgData[mi]!=="undefined"){
                        //alert(JSON.stringify(this.state.stPgData[mi]));
                    }
                    
                }
return(<>{typeof stPgData[mi]!=="undefined"?<>
    {/*not in use <Home_Banner_n/> */}
    
    {typeof stPgData[mi]["home_banner"]!=="undefined"?<Home_Banner_WithNews_n lsData={stPgData[mi]["home_banner"]} />:''}
    {typeof stPgData[mi]["home_banner2div"]!=="undefined"?<Home_Banner2Div_n lsData={stPgData[mi]["home_banner2div"]} />:''}
    {typeof stPgData[mi]["list_slider"]!=="undefined"?<Article_Blogs_n lsData={stPgData[mi]["list_slider"]} />:''}

    {typeof stPgData[mi]["card_list"]!=="undefined"?<Card_List lsData={stPgData[mi]["card_list"]} />:''}
    
    {typeof stPgData[mi]["img_text_desc_img_left"]!=="undefined"?<Left_Img_Txt_Desc_n lsData={stPgData[mi]["img_text_desc_img_left"]} />:''}
    {typeof stPgData[mi]["img_text_desc_img_right"]!=="undefined"?<Left_Img_Txt_Desc_n lsData={stPgData[mi]["img_text_desc_img_right"]} img_right="true" />:''}
    
    {typeof stPgData[mi]["image_collarge_grid"]!=="undefined"?<Image_Collarge_Grid_n lsData={stPgData[mi]["image_collarge_grid"]} />:''}
    {typeof stPgData[mi]["video_slider"]!=="undefined"?<Video_Slider_n lsData={stPgData[mi]["video_slider"]} />:''}

    {typeof stPgData[mi]["text_thumbnail"]!=="undefined"?<Text_Thumbnail_n lsData={stPgData[mi]["text_thumbnail"]} />:''}

    {typeof stPgData[mi]["text_description"]!=="undefined"?<Txt_Description_n lsData={stPgData[mi]["text_description"]} />:''}

    {typeof stPgData[mi]["article_listing"]!=="undefined"?<Article_Listing_n isSort={loc==="e_news"?true:false} sortparam={["entry_date",false,"date"]} lsData={stPgData[mi]["article_listing"]} />:''}

    {typeof stPgData[mi]["blog_listing"]!=="undefined"?<Blog_Listing_New isSort={loc==="e_news"?true:false} sortparam={["entry_date",false,"date"]} lsData={stPgData[mi]["blog_listing"]} />:''}

    {typeof stPgData[mi]["single_img_text"]!=="undefined"?<Single_Img_Msg_n lsData={stPgData[mi]["single_img_text"]} />:''}

    {typeof stPgData[mi]["image_gallery"]!=="undefined"?<ImageGallery_n lsData={stPgData[mi]["image_gallery"]} />:''}

    {typeof stPgData[mi]["collapse_pan"]!=="undefined"?<Collapse_Pan_Images_n lsData={stPgData[mi]["collapse_pan"]}/>:''}
    
    {typeof stPgData[mi]["testimonials"]!=="undefined"?<Testimonials_n lsData={stPgData[mi]["testimonials"]}/>:''}
    
    
    </>:''}</>);
   
        })
        }
        {/* end of cms part */}

        <div className="container-sm container-fluid px-1 py-5 mx-auto">
        <div className="row d-flex justify-content-center">
        <div className="col-xl-12 col-lg-12 col-md-10 text-center">
                {/* <div className="col-lg-4 col-md-5 mb-lm-60px col-sm-12 col-xs-12 w-sm-100">
                    <div className="contact-info-wrap">
                        <h2 className="title" >Contact Us</h2>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">OFFICE</span>
                            </div>
                            <div className="contact-info-dec">
                                <p>SA-2, 1st Floor,<br></br>Dawa Bazaar Freeganj,<br></br>Ujjain MP - 456010</p>
                            </div>
                        </div>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">CONTACTS</span>
                            </div>
                            <div className="contact-info-dec">
                                <p><a href="tel:911808890114">+91 1800-889-0114</a></p>
                            </div>
                        </div>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">EMAIL</span>
                            </div>
                            <div className="contact-info-dec">
                            <p><a href="mailto://info@mbdhwellness.com">info@mbdhwellness.com</a></p>
                            </div>
                        </div>
                       
                    </div>
                </div> */}
                <div className="card dia_cart border-box-3">
                    <div className="contact-form11">
                        <div className="heading11 contact-title mb-30 pb-70px">
                            <h2 className="title float-left">Write to Us</h2>
                        </div>
                        <form className="contact-form-style form-control11 " name="contact-form" id="contact-form">
                        <input type="hidden" name="curl_type" id="curl_type" value="addContactRequest" />  
                <input type="hidden" name="m_type" id="m_type" value="addContactRequest" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCont" />
                            <div className="row">
                                <div className="col-lg-6 mb-2 pl-2 pr-2">
                                <div className="form-group">
                                <label className="form-control-label px-3 text-left">Name *</label>
                                    <input className="required form-control required" name="_name" id="_name" placeholder="Full Name" type="text" />
                                </div>
                                </div>
                               
                                <div className="col-lg-6 mb-2 pl-2 pr-2">
                                <div className="form-group">
                                <label className="form-control-label px-3 text-left">Contact No *</label>
                                    <input className="required form-control required" name="contact_no" id="contact_no" placeholder="Contact No*" type="text" />
                                </div>    
                                </div>
                                <div className="col-lg-6 mb-2 pl-2 pr-2">
                                <div className="form-group">
                                <label className="form-control-label px-3 text-left">Email</label>
                                    <input className="form-control" name="email" id="email" placeholder="Email" type="email" />
                                </div>    
                                </div>
                                {/* <div className="col-lg-12" >
                                    <input name="subject" placeholder="Subject*" type="text" />
                                </div> */}
                              
                                <div className="col-lg-12 mb-2 pl-2 pr-2" >
                                    <div className="form-group">
                                    <label className="form-control-label px-3 text-left">Message *</label>
                                    <textarea className="required form-control  mb-2 pl-2 pr-2" id="message" name="message" placeholder="Your Message*"></textarea>
                                    </div>
                                    <p>
                                We would like to keep you updated with news and information on our latest products & services.*
                                </p>
                            
                                {/* <p> <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
<label for="vehicle1">Yes, please contact me and keep me up to date.</label></p>
<p>*If left blank we will not contact you.</p> */}
                                    <button onClick={()=>addContactReq()} className="btn btn-primary btn-hover-dark _btnSetColor111 mt-4 btn-1"  type="button">Submit</button>
                                <p style={{paddingTop:"20px"}}>
                                We will not share your information with 3rd parties.You can contact us to stop receiving updates at any time in the future by emailing info@dsbgroup.in You can also click on the unsubscribe button at the bottom of every marketing message. View our <a href="/terms_condition">Terms & Conditions</a> and our<a href="/privacy_policy">Privacy Policy</a>.
                                </p>
                                </div>
                            </div>
                        </form>
                        <p className="form-messege"></p>
                    </div>
                </div>
            </div>
        </div>
        </div>
     
        <Footer/>
</>)
}
export default Contact_Us;