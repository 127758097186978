import $ from 'jquery';
import React,{useEffect,useState} from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import UserToken from '../function_lib/userToken';
import {getAccessRolesNew,CallCMSMapMethod} from '../function_lib/common_cms';
import {getRoleDetails,CallCMSMapMethod_CBack,pgIndexLimit} from '../function_lib/common_cms';
import logoImg from '../assets/images/logo/logo.png';

import './front_header.css';

export const Front_Header=(props)=>{
    const [metaHeaders,setMetaHeaders]=useState({
        top_title:"DSB Group",
        top_description:"DSB Group",
        top_keywords:"DSB Group"
    })

    const _headerType="e-normal";
    const uri="../login";
    let listRoles=[];

    const meSetMetaHeaders=(loc,listMeta)=>{
        if(loc==""){
            loc="home";
        }
        //alert(loc);
        for(let m=0;m<listMeta.length;m++)
            {
        if(listMeta[m]["col2"].toUpperCase()===loc.toUpperCase() || listMeta[m]["col2"].toUpperCase()==="../"+loc.toUpperCase()){
        // alert(JSON.stringify(listMeta[m]));

         let  _topTitle=listMeta[m]["col3"];
         let _topKeywords=listMeta[m]["col4"];
         let _topDescription=listMeta[m]["col5"];
            document.title=_topTitle;

        document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);

            document.querySelector('meta[property="og:title"]').setAttribute("content", _topTitle);
            document.querySelector('meta[property="og:description"]').setAttribute("content", _topDescription);

        //     setMetaHeaders({
        //     top_title:listMeta[m]["col3"],
        // top_description:listMeta[m]["col4"],
        // top_keywords:listMeta[m]["col5"]
        //    });
        
        }
    }

    }

    const getMenus=()=>{
        var pgMap=[];
        pgMap.push({name:"curl_type",value:"getAccessToken"});
        pgMap.push({name:"res_func",value:"callBackAccess"});
      
        pgMap["res_func"]="callBackAccess";
        pgMap["curl_type"]="getAccessToken";
        CallCMSMapMethod_CBack(this,pgMap,function(data,msg){
          
            //alert(props.loc);

            localStorage.setItem('userData',data);
            UserToken.setUserObject(localStorage.getItem("userData"));
            listRoles= UserToken.getAccessRoles();
            

            meSetMetaHeaders(props.loc,listRoles);

            //alert(JSON.stringify(listRoles));
            //this.setMetaTags(listRoles);
            // clsObject.setState({stMenuAccess:listRoles},()=>{
            //     clsObject.forceUpdateHandler();
            // });
          var listHtm=  getAccessRolesNew(listRoles); 
          $("#menu_opt").html(listHtm);
          $("#mob_menu").html(listHtm);

        });
    }


    useEffect(()=>{
        getMenus();
    },[props.loc])


   if(_headerType==="e-search"){
        return(<div>
            <HelmetProvider>
    <div>
      <Helmet>
       

      </Helmet>
      {/* <h1>Hello, World!</h1> */}
    </div>
  </HelmetProvider>
            <div className="header section header-bottom">
            {/* top header commented */}
        {/* <div className="header-top section-fluid bg-black">
            <div className="container">
                <div className="row row-cols-lg-2 align-items-center">
                    
                    <div className="col text-center text-lg-left">
                        <div className="header-top-massege">
                            <p>Welcome to Fast RC</p>
                        </div>
                    </div>
                    
                    <div className="col d-none d-lg-block">
                        <div className="header-top-set-lan-curr d-flex justify-content-end">
                            
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle" data-bs-toggle="dropdown"> Language : (ENG) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">English</a></li>
                               </ul>
                            </div>
                           
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle pr-0 border-0" data-bs-toggle="dropdown">Currency : (USD) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">USD $</a></li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div> */}
        {/* end of top header */}
        
        <div className="green-dark11 header-bottom11 d-none d-lg-block">
            <div className="container position-relative">
                <div className="d-flex justify-content-center">
                   
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
                    <div className="p2 custom-search input-group">
   
</div>
                    
                    <div className="col align-self-center">
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></a>
                                <div className="dropdown_search">
                                    <div className="input-group">
                                        <input className="form-control" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </div>
                               
                                </div>
                            </div>
                            <a href={uri} className="white1">My Orders</a>
                            {/* <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                            </a> */}
                            <div id="user_opt_desk" className="header-bottom-set dropdown">
                               
                            </div>
                            
                            <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="ion-android-cart white1"></i>
                                <span className="header-action-num _cart_elm_count"></span>
                                 {/* <span className="cart-amount _cart_elm_sun"></span>  */}
                            </a>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        
        <div className="green-dark11 pb11-1  border-box-2 d-lg-none sticky-nav bg-white">
            <div className="container position-relative">
                <div className="row align-self-center">
                    
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img className="logoimg" src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
        
                    
                    <div className="col align-self-center" style={{"justify-content":"flex-end"}}>
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn white11"><i
                                        className="icon-magnifier white11"></i></a>
                                <div className="dropdown_search">
                                    <form className="action-form" action="#">
                                        <input className="form-control _setSearchKey" id="txtSearchHead_sm" name="txtSearchHead_sm" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </form>
                                </div>
                            </div>
                          
                            <div id="user_opt_mob" className="header-bottom-set dropdown">
                            </div>
                            
                            <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="ion-android-cart white1"></i>
                                <span className="header-action-num _cart_elm_count"></span>
                                {/* <span className="cart-amount _cart_elm_sun"></span>  */}
                            </a>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu white11"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div className="green d-none header-bottom11 d-lg-block sticky-nav">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="main-menu manu-color-white">
                        <a href="#offcanvas-mobile-menu" style={{"line-height":"45px","padding-right":"10px"}} className="header-action-btn white header-action-btn-menu offcanvas-toggle">
                                <i className="icon-menu"></i>
                            </a>
                            <ul id="menu_opt">
                            {/* <Comp_Link_Acccess roles={this.state.stMenuAccess}/> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
        
            <div className="offcanvas-menu mb-4">
                <ul id="mob_menu">
                </ul>
            </div>
        </div>
        </div>
        </div>
        )
    }else if(_headerType==="e-normal"){
        return(<div>
            <HelmetProvider>
    <div>
      <Helmet>
        <title>{metaHeaders.top_title}</title>
        
        <meta name="title" content={metaHeaders.top_title}/>
        <meta name="keywords" content={metaHeaders.top_keywords}/>
        <meta name="description" content={metaHeaders.top_description} />

        <meta property="og:title" content={metaHeaders.top_title}/>
        <meta property="og:description" content={metaHeaders.top_description}/>
{/* <meta property="og:image" content="https://example.com/images/recipe.jpg"> */}
{/* <meta property="og:url" content="https://example.com/best-recipes"> */}
<meta property="og:type" content="website"/>

      </Helmet>
      {/* <h1>Hello, World!</h1> */}
    </div>
  </HelmetProvider>
            <div className="header-2 header section header-bottom">
            {/* top header commented */}
        <div className="header-top section-fluid bg-black">
            <div className="container">
                <div className="row row-cols-lg-2 align-items-center">
                    
                    <div className="col text-center text-lg-left">
                        <div className="header-top-massege">
                            <p>Welcome to DSB Group</p>
                        </div>
                    </div>
                    
                    <div className="col d-none d-lg-block">
                        <div className="header-top-set-lan-curr d-flex justify-content-end">
                            
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle" data-bs-toggle="dropdown"> Language : (ENG) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">English</a></li>
                               </ul>
                            </div>
                           
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle pr-0 border-0" data-bs-toggle="dropdown">Currency : (USD) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">USD $</a></li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        {/* end of top header */}
        
        <div className="green-dark11 header-bottom11 d-none d-lg-block">
            <div className="container position-relative">
                <div className="d-flex justify-content-center">
                   
                  
                    
                  
                    
                   
                   
                </div>
            </div>
        </div>
        
        <div className="green-dark11 pb11-1  border-box-2 d-lg-none sticky-nav bg-white">
            <div className="container position-relative">
                <div className="row align-self-center">
                    
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img className="logoimg" src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
        
                    
                    <div className="col align-self-center" style={{"justify-content":"flex-end"}}>
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn white11"><i
                                        className="icon-magnifier white11"></i></a>
                                <div className="dropdown_search">
                                    <form className="action-form" action="#">
                                        <input className="form-control _setSearchKey" id="txtSearchHead_sm" name="txtSearchHead_sm" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </form>
                                </div>
                            </div>
                          
                            <div id="user_opt_mob" className="header-bottom-set dropdown">
                            </div>
                            
                            <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="ion-android-cart white1"></i>
                                <span className="header-action-num _cart_elm_count"></span>
                                {/* <span className="cart-amount _cart_elm_sun"></span>  */}
                            </a>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu white11"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div className="green d-none header-bottom11 d-lg-block sticky-nav">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="main-menu manu-color-white">
                        <div className="header-logo col">
                            <a class="bg-white11" href="/"><img className="logoimg pr-5" src={logoImg} alt="Site Logo" /></a>
                        </div>
                      
                            <ul id="menu_opt">
                            {/* <Comp_Link_Acccess roles={this.state.stMenuAccess}/> */}
                            </ul>
                            <a href="#offcanvas-mobile-menu"  className="pl-5 header-action-btn white header-action-btn-menu offcanvas-toggle">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
        
            <div className="offcanvas-menu mb-4">
                <ul id="mob_menu">
                </ul>
            </div>
        </div>
        </div>
        </div>
        )

}
}
export default Front_Header;