import {appGetUrlPath} from '../function_lib/common_cms';
import './article_blogs_new.css';

const Blog_Listing_New=(props)=>{
    let appPath=appGetUrlPath();
    
let title="";
if(props.lsData.length!=0){
title=props.lsData[0]["g_title"];
}

    return(
        <section class="article_section_globall bg-area1">
    <div class="container py-5">
        <div class="row g-4">
            <div class="col-12">
            <div class="heading page__title-bar">
                    <h3 class="title">{title}</h3>
            </div>

            </div>

            {/* slider mapping */}
            {/* <div class="new-product-slider swiper-container slider-nav-style-1">
            <div class="new-product-wrapper swiper-wrapper"> */}
            {/* <!-- Card 1 --> */}
            {props.lsData.map((item,index)=>{
                 var urlTitle= item["col1"];
                 urlTitle=urlTitle.replace("?","_");
                 urlTitle=urlTitle.replace(" ","-");
                 let clpUrl="..\\blog\\"+urlTitle+"?rf="+item["e_id"];
                 if(item["col7"]!==""){
                     clpUrl="..\\"+item["col7"];
                 }
                 if(item["col7"]==="read_now"){
                     //alert("i m in condition "+lsData[i]["col7"]+urlTitle+"?rf="+lsData[i]["e_id"]);
                     clpUrl="..\\"+item["col7"]+"\\"+urlTitle+"?rf="+item["e_id"];
                     //alert(clpUrl);
                 }
                return(
            <div class="col-sm-12 col-md-6 col-lg-4 swiper-slide11 pr-4 pb-4">
                <a href={clpUrl}>
                <div class="card news-card">
                    {/* <span class="news-category">{item["col1"]}</span> */}
                    <img src={appPath+item["col4"]} class="card-img-top news-image" />
                    <div class="card-body">
                    <h6 class="title"><b>{item["col1"]}</b></h6>
                        {/* <h5 class="card-title">{item["col1"]}</h5> */}
                        <p class="card-text text-truncate">{item["col2"]}</p>
                        {/* <a href="#" class="news-category">Read More</a> */}
                    </div>
                </div>
                </a>
            </div>)
            })
            }
            
           {/* </div>
           <div class="swiper-buttons">
<div class="swiper-button-next"></div>
<div class="swiper-button-prev"></div>
</div>
           </div> */}
           {/* end 2 div of slider mapping */}

            

           
        </div>
    </div>
   </section>
    );
}

export default Blog_Listing_New